.category__right, #tinymce {
	p {
		margin: 0 0 80px;
		color: #363636;
		font-size: 18px;

		a {
			color: #363636;
			text-decoration: underline;
			transition: .3s linear;
			&:hover {
				color: #ff3d4b;
			}
		}

		@media screen and (max-width: 1199px) {
			margin-bottom: 40px;
		}

		@media screen and (max-width: 575px) {
			font-size: 16px;
		}
	}

	.bold-paragraph {
		margin: 0 0 80px;
		color: #363636;
		font-family: 'Golos Medium';
		font-size: 40px;
		font-weight: 500;
		line-height: 1;

		@media screen and (max-width: 1199px) {
			margin-bottom: 40px;
			font-size: 32px;
		}

		@media screen and (max-width: 575px) {
			font-size: 22px;
		}
	}
}

.form__group--required::after {
	display: none !important;
}

input.error + span.error {
	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: calc(50% - 6px);
		width: 12px;
		height: 12px;
		background: url(../img/icons/star.svg) center/contain no-repeat;
	}
}

input.agree {
	position: absolute;
	opacity: 0;
	display: block;
}

.content {
	padding-bottom: 80px;

	@media screen and (max-width: 991px) {
		padding-bottom: 60px;
	}
}


.menu__item {
	.menu__link {
		border-bottom-color: transparent;
	}

	&.js-menu-toggle{
		.menu__link {
			border-bottom-color: transparent;

		}
	}

	&:hover {
		.menu__link {
			border-bottom-color: #ff3d4b;
		}
	}
}

.abox--grey {
	background: #eee;
}

.cookie {
	display: none;

	&_open {
		display: block;
	}
}

.uploader_files {
	display: inline-flex;
	flex-wrap: wrap;
	margin-top: 15px;

	.uploader_file {
		color: rgba(238, 238, 238, .5);
		font-size: 16px;
		margin-right: 20px;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-content: center;
		align-items: center;

		span {
			margin-left: 7px;
			display: block;
			width: 17px;
			height: 17px;
			background: url('/front/site/img/icons/close-red.svg') center/contain no-repeat;
			cursor: pointer;
			top: 3px;
			position: relative;
		}

		.modal & {
			color: #363636;
		}
	}
}

input[type="file"] {
	display: none;
}

.about-carousel {
	padding-top: 80px;
	.item {
		img {
			height: auto !important;
		}
	}

	@media screen and (max-width: 1199px) {
		padding-top: 62px;
	}
}


